module.exports = {
  // App Info
  appstore_link         : "https://apps.apple.com/app/id1593332528",                                  // Enter App Store URL.
  playstore_link        : null,                                  // Enter Google Play Store URL.
  google_analytics_ID   : "a",                                        // Enter Google Analytics ID or ""
  presskit_download_link: "",                                  // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file). 
  video_or_screenshot   : "video",                                           // "screenshot" or "video"
  app_url               : "https://y2gmapper.app",                            // Domain of your website without path_prefix.
  path_prefix           : "/",                                                    // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name              : "Y2G Mapper",
  app_price             : "$0.99",
  app_description       : "Redirect Yelp links to Google Maps from Safari in iOS",
  app_keywords          : ["redirect", "mapper", "safari", "extension", "yelp2google", "yelp2gmaps", "y2gmaps", "yelp redirect", "yelp to google", "safari extension", "safari utility", "yelp", "google"],

  // Personal Info
  your_name              : "Polina",
  your_link              : null,
  your_city              : "Bay Area",
  email_address          : "contact@y2gmapper.app",
  linkedin_username      : null,
  facebook_username      : null,
  instagram_username     : null,
  twitter_username       : null,
  github_username        : null,
  youtube_username       : null,

  // Features List
  features               : [
    {
      title: "Works instantly",
      description:
        "The extension seamlessly integrates into Safari browser and redirects all visited Yelp business pages to Google Maps application on your device.",
      fontawesome_icon_name: "sync",
    },
    {
      title: "Made for iOS 15",
      description:
        "Y2G Mapper was built from ground up for iOS 15 and iPadOS 15. It has a native interface, and it is fully integrated with all system features. Note that this extension is only possible on iOS 15.",
      fontawesome_icon_name: "mobile",
    },
    {
      title: "No data collected",
      description:
        "We take your privacy seriously: Y2G Mapper does not collect any of your personal or browsing data. Only your settings are stored on your device. No tracking. Period.",
      fontawesome_icon_name: "hand-paper",
    },
    {
      title: "Easy to configure",
      description:
        "By default, Y2G Mapper will automatically redirect all yelp.com business links to Google Maps. But you can easily override this behavior and be asked if you want to open business page in Google Maps.",
      fontawesome_icon_name: "magic",
    },
  ],
  header_background             : "#F65353",
  topbar_title_color            : "#ffffff",
  cover_overlay_color_rgba      : "rgba(54, 59, 61, 0.8)",
  device_color                  : "black",                          // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color         : "ffffff",
  primary_text_color            : "#000",
  content_width                 : "1170px",
  font                          : `"Helvetica Neue", sans-serif`,
  link_color                    : "#fffff5",
  app_title_color               : "#ffffff",
  app_price_color               : "#ffffff",
  app_description_color         : "#ffffff",
  feature_title_color           : "#000000",
  feature_text_color            : "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color : "#666666",
  social_icons_background_color : "#e6e6e6",
  footer_text_color             : "#666666",
}
